<template>
	<div>

		<form class="payment-form" v-show="activetab == 'form'">
			<!--p align="right"><a href="/" class="text-primary" style="color:#005daa;">[GO BACK]</a></p-->
			<p align="center"><img alt="Rotary District 7030 Logo logo" class="logo" src="../assets/rotary7030_logo.png">
			</p>
			<h2 align="center">District Dues Payment Form</h2>
			<select class="form-control" v-model="clubname" @change="clubnameChange">
				<option value="0" selected="true" disabled>--- Select your club ---</option>
				<option v-for="(club, index) in clubs" :key="index"
					:value="{ id: club.id, clubname: club.data.clubname, size: club.data.clubsize, amt: club.data.dues, arrears: club.data.arrears, paid: club.data.paid, emails: club.data.emails }"
					:disabled="club.data.dues < 1">{{ club.data.clubname }}</option>
			</select>
			<!-- <table>
            <tr v-for="(club, index) in clubs" :key="index">
                <td>{{club.id}}</td><td>{{club.data.clubname}}</td>
            </tr>
        </table> -->
			<p style="margin-bottom:40px;">&nbsp;</p>
			<div v-show="clubname != 0 && !showstripe">
				<div>
					<h2>Club Information</h2>
				</div>
				<table class="summary-table">
					<tr>
						<td>Number of members in your club (as at 1st July 2022)</td>
						<td align="right">{{ clubname.size }}</td>
					</tr>
					<tr>
						<td>Per Capita Charges (USD) <br><small>District dues - $25.00<br>Disaster relief fund -
								$2.00</small></td>
						<td align="right">$27.00</td>
					</tr>
					<tr>
						<td><strong>Current Dues for 2023-2024 (USD)</strong></td>
						<td align="right"><strong>${{ parseInt(clubname.size * 27) | currency }}</strong></td>
					</tr>
					<tr>
						<td><strong>Arrears (USD)</strong></td>
						<td align="right"><strong>${{ clubname.arrears | currency }}</strong></td>
					</tr>
					<tr>
						<td><strong>Paid to Date (USD)</strong></td>
						<td align="right"><strong>(${{ clubname.paid | currency }})</strong></td>
					</tr>
					<tr>
						<td><strong>Total Due (USD)</strong></td>
						<td align="right"><strong>${{ maxpayment | currency }}</strong></td>
					</tr>
				</table>
				<div>
					<div class="form-radio">
						<input type="radio" id="payfull" value="1" v-model="picked">
						<label for="payfull">Pay full amount</label>
					</div>
					<div class="form-radio">
						<input type="radio" id="paypart" value="2" v-model="picked">
						<label for="paypart">Pay other amount</label>
						<input type="number" min="100" step="0.1" class="form-control" v-show="picked == 2"
							v-model="amountdue" :max="maxpayment" placeholder="Enter payment amount" />
						<small class="error" v-show="picked == 2">{{ payamounterror }}</small>
					</div>
				</div>

				<div>
					<div>
						<h2>Billing Information <small>(as appears on credit card)</small></h2>
					</div>
					<div>
						<input type="text" class="form-control" v-model="fullname" placeholder="Full name" />
					</div>
					<div>
						<input type="email" class="form-control" v-model="emailaddress" placeholder="Email address" />
					</div>
					<div>
						<input type="number" step="1" class="form-control" v-model="phone" placeholder="Phone number" />
					</div>
					<div>
						<button type="button" class="btn btn-primary" :disabled="!isformvalid"
							@click="btncontinueClick">Continue</button>
					</div>

				</div>

			</div>

			<div v-show="showstripe && clubname != 0">
				<div>
					<h2>Credit Card</h2>
				</div>
				<div class="alert alert-primary" role="alert">
					<h3>Notice to Scotiabank Credit Card Customers</h3><br />&nbsp;
					<h5 class="mb-5">Please contact the bank's credit card center before attempting to make a payment using
						your credit card.</h5><br />&nbsp;
				</div>
				<p align="right"></p>
				<table class="summary-table">
					<tr>
						<td>Payment amount</td>
						<td align="right">${{ amountdue | currency }}</td>
					</tr>
					<tr>
						<td>Processing fees</td>
						<td align="right">${{ fees | currency }}</td>
					</tr>
					<tr>
						<td><strong>Total</strong><br><small>Your payment details will be processed by Soca Islands LLC via
								Stripe.com.</small></td>
						<td align="right"><strong>${{ (stripeamount / 100) | currency }}</strong></td>
					</tr>
				</table>
				<div class="form-radio">
					<input type="checkbox" id="terms" value="true" v-model="agreeterms">
					<label for="terms">I have read and agree to the terms and conditions.</label>
				</div>
				<div>
					<input type="hidden" value="" name="stripeamount" id="stripeamount" v-model="stripeamount">
					<fieldset class="form-group">
						<div class="form-row col-12 form-control">
							<label for="card-element" style="width:100%;">

							</label>
							<!--input type="text" v-model="fullname" class="form-control" style="margin-bottom:5px;" placeholder="Cardholder's full name"/-->

							<div id="card-element" class=""></div>

							<!-- Used to display form errors. -->
							<div id="card-errors" role="alert" style="color:#ff0000;"> </div>
						</div>
					</fieldset>
					<button type="button" :data-secret="clientsecret" id="card-button"
						class="btn btn-primary btn-block btn-lg mb-2" :disabled="!ispaymentformvalid || isbusy">
						<span v-show="!isbusy">Pay Now</span>
						<span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status"
								aria-hidden="true"></span> Please wait ...</span>
					</button>
					<button type="button" class="btn back-button" @click="btnbackClick">Go back...</button>
				</div>
			</div>
		</form>
		<div align="center" v-show="activetab == 'thankyou'">
			<h2>Thank you for your payment!</h2>
			<a href="/" class="btn btn-primary" style="padding:10px 20px; text-decoration:none;">Continue</a>
		</div>

	</div>
</template>
<script type="text/javascript" src="//js.stripe.com/v3/"></script>
<script>
const fb = require('../firebaseConfig.js')
export default {
  name: 'RotaryDues',
  props: {
    msg: String
  },
  filters: {
      currency (value) {
          if (!value) return '0.00'
          value = Number(value)
          return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  computed:{
      isformvalid(){
          //return true
          if(this.picked==2 && this.amountdue > this.maxpayment) return false
          if(this.picked==2 && this.amountdue < 100) return false
          if(!this.fullname) return false
          if(!this.emailaddress) return false
          if(!this.phone) return false
          return true
      },
      ispaymentformvalid(){
          if(!this.agreeterms) return false
          return true
      },
      payamounterror(){

          if(this.picked==2 && this.amountdue < 100){

            return 'Error: Invalid payment amount'
          }

          if(this.picked==2 && this.amountdue > this.maxpayment){
            return 'Error: You cannot pay more than you currently owe.'
          }

          return ''
      },
      /*fees(){
          return (((this.amountdue + 0.35)/0.945)-this.amountdue)
      }*/

  },
  data (){ 
      return {
          dues:27,
          picked:'',
          clubname:'0',
          payamount:'',
          amountdue: 540,
          maxpayment: 540,
          fullname:'',
          emailaddress:'',
          phone:'',
          stripeamount:0,
          fees:0,
          isbusy:false,
          clientsecret:'',
          showstripe:false,
          agreeterms:false,
          clientsecret:'',
          pytintent:'',
          API_URL:'https://dues.rotarydistrict7030.org/api',
          clubs:[],
          activetab:'form',
          ordernum:'',
          seed:[],
      } 
  },
  methods:{
    clubnameChange(){
        this.showstripe = false
        this.amountdue =  this.maxpayment = ((Number(this.clubname.size)*this.dues) + Number(this.clubname.arrears)) - Number(this.clubname.paid)
        //this.amountdue = (Number(this.clubname.size)*this.dues)-Number(this.clubname.paid)
    },
    btnbackClick(){
        this.showstripe = false
    },
    btncontinueClick(){
        this.amountdue = (this.picked==2) ? Number(this.amountdue) : Number(this.maxpayment)
        this.stripeamount = Math.trunc(((this.amountdue + 0.30)/0.95)*100)
        this.fees = ((this.amountdue + 0.30)/0.95)-this.amountdue
        this.showstripe = true
        let $this = this
        const stripe = Stripe('pk_live_NO5i9A0rJetFGqZ99mLPeSpP') //pk_test_itCmziCcsavym8T71gROTOhj  pk_live_NO5i9A0rJetFGqZ99mLPeSpP

        var elements = stripe.elements()

        let style = {
            base: {
                color: '#32325d',
                fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '1.15em',
                '::placeholder': {
                      color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }

        // Create an instance of the card Element.
        let card = elements.create('card', {style: style})

        // Add an instance of the card Element into the `card-element` <div>.
        card.mount('#card-element');

        // Handle real-time validation errors from the card Element.
        let displayError = document.getElementById('card-errors')
        card.addEventListener('change', function(event) {
            $this.isbusy = false
            if (event.error) {
                displayError.textContent = event.error.message
            } else {
                displayError.textContent = ''
            }
        })

        let submitButton = document.getElementById('card-button')

        submitButton.addEventListener('click', async () =>{

            //console.log(ev)

            this.isbusy = true

            if(this.fullname==''  || this.fullname.length<4){
                this.isbusy = false
                displayError.textContent = "Cardholder's name is missing"
                return false
            }

            let orderDesc = "Payment of Rotary District 7030 Dues"

            if(this.clientsecret==''){
                this.ordernum = Math.floor(Date.now()/100)


                //get stripe payment intent
                //console.log("stripe amount: ", this.stripeamount)
                if(this.stripeamount <1) return false

                let striperesponse = await fetch(this.API_URL+'/stripe/create/'+this.ordernum+'!'+this.stripeamount+'!'+encodeURI(this.fullname)+'!'+encodeURI(this.clubname.clubname)+'!'+encodeURI(this.emailaddress)).then((response)=> {
                  return response.json()
                })

                this.clientsecret = striperesponse.client_secret
                this.pytintent = striperesponse.payment_intent
                //console.log(this.clientsecret, " / ", this.pytintent)
            }


            stripe.confirmCardPayment(this.clientsecret,
                {
                  payment_method: {
                  card: card,
                  billing_details: {
                    name: this.fullname,
                    phone: this.phone,
                    email: this.emailaddress,
                  },
                },
            }).then(async (result) =>{
                if (result.error) {
                    document.getElementById('card-errors').textContent = result.error.message + " Please contact your bank for further information." 
                    this.isbusy = false
                } else{
                    //console.log('The payment has been processed!')

                    //this.amountdue

                    fb.clubsCollection.doc(this.clubname.id).update({
                        dues: fb.firebase.firestore.FieldValue.increment((-1)*this.amountdue),
                        paid: fb.firebase.firestore.FieldValue.increment(this.amountdue),
                    })

                    let emailaddresses = 'governor@rotarydistrict7030.org, secretariat@rotarydistrict7030.org, treasurer@rotarydistrict7030.org, reuben.john@bdoecc.com, lislechased7030@gmail.com'

                    if(this.clubname.emails.president) emailaddresses +=  ", " + this.clubname.emails.president

                    if(this.clubname.emails.secretary) emailaddresses +=  ", " + this.clubname.emails.secretary

                    if(this.clubname.emails.treasurer) emailaddresses += ", " + this.clubname.emails.treasurer

                    //console.log('email', emailaddresses)

                    let data = {
                        club:{clubemails:emailaddresses, clubname:this.clubname.clubname, dues:this.clubname.amt},
                        payment:{orderid: this.ordernum, paid:this.amountdue, payor: this.fullname + ' - ' +  this.emailaddress + ' - ' + this.phone }
                    }

                    await fetch(this.API_URL+'/sendemail',{
                        method: 'POST', 
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'same-origin', 
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify(data)
                    }).then((response)=> {
                        
                        return response.json()

                    })

                    this.clientsecret = ''
                    this.isbusy = false
                    
                    this.activetab = "thankyou"


                }
            })

        })
    }
  },
  mounted(){
      //this.ordernum = Math.floor(Date.now() / 1000)
      fb.clubsCollection.orderBy("clubname", "asc").onSnapshot((querySnapshot)=>{
          let i=0
          this.clubs.length=0
          querySnapshot.forEach((doc)=>{
              this.clubs.push({id:doc.id, data:doc.data()})
              i++
          })
      })


/*       this.seed =[
    {
        "clubname": "Antigua",
        "president": "mspencer769@gmail.com",
        "secretary": "rozannee@gmail.com",
        "treasurer": "linda.watkins@twhantigua.com",
        "clubsize": 60,
        "dues": 1620
    },
    {
        "clubname": "Antigua-Sundown",
        "president": "marettoellis@gmail.com",
        "secretary": "klewis.lsp@gmail.com",
        "treasurer": "jdormond2012@hotmail.com",
        "clubsize": 41,
        "dues": 1107
    },
    {
        "clubname": "Aranguez",
        "president": "kevanstbrice@gmail.com",
        "secretary": "samantha.k.boodoosingh@gmail.com",
        "treasurer": "aaronlesaldo106@gmail.com",
        "clubsize": 14,
        "dues": 378
    },
    {
        "clubname": "Arima",
        "president": "aneilmhrj@yahoo.com",
        "secretary": "shell196927@gmail.com",
        "treasurer": "hsampath@live.com",
        "clubsize": 21,
        "dues": 567
    },
    {
        "clubname": "Aruba",
        "president": "luis_oduber@yahoo.com",
        "secretary": "sanjuluidens@yahoo.com",
        "treasurer": "edsel.lopez@aw.gt.com",
        "clubsize": 46,
        "dues": 1242
    },
    {
        "clubname": "Barbados",
        "president": "ermine.darroux@gmail.com",
        "secretary": "jamellaforde13@gmail.com",
        "treasurer": "infonetpw@gmail.com",
        "clubsize": 91,
        "dues": 2457
    },
    {
        "clubname": "Barbados South",
        "president": "memurrellandco@gmail.com",
        "secretary": "auglyn@caribsurf.com",
        "treasurer": "damian.branford104@gmail.com",
        "clubsize": 47,
        "dues": 1269
    },
    {
        "clubname": "Barbados West",
        "president": "gale1reeve@aol.com",
        "secretary": "lydia_mccollin@hotmail.com",
        "treasurer": "johnhadchity@caribsurf.com",
        "clubsize": 40,
        "dues": 1080
    },
    {
        "clubname": "Basse Terre",
        "president": "michelvirassamy8@gmail.com",
        "secretary": "marie.letoublon@yahoo.fr",
        "treasurer": "eric.lbe@orange.fr",
        "clubsize": 11,
        "dues": 297
    },
    {
        "clubname": "Bonaire",
        "president": "jan@okhuijsen.com",
        "secretary": "wietzekoopman@gmail.com",
        "treasurer": "Simonevanhengstum@hotmail.com",
        "clubsize": 26,
        "dues": 702
    },
    {
        "clubname": "Cayenne",
        "president": "brahim-ahmed@live.fr",
        "secretary": "laurence@lvconsultants.fr",
        "treasurer": "mdbarclay@orange.fr",
        "clubsize": 38,
        "dues": 1026
    },
    {
        "clubname": "Central Port of Spain",
        "president": "tanya.western@dalecarnegie.com",
        "secretary": "designchangett@gmail.com",
        "treasurer": "gaila@navarros.com",
        "clubsize": 39,
        "dues": 1053
    },
    {
        "clubname": "Chaguanas",
        "president": "shepard.seepersad@gmail.com",
        "secretary": "kavitasantay@gmail.com",
        "treasurer": "malcolmrs15@hotmail.com",
        "clubsize": 22,
        "dues": 594
    },
    {
        "clubname": "Cœur Caraïbes (Lamentin)",
        "president": "Mike.veldwachter@gmail.com",
        "secretary": "damien.jeanbaptiste@thrill.fr",
        "treasurer": "nathalie.jb972@gmail.com",
        "clubsize": 17,
        "dues": 459
    },
    {
        "clubname": "Corriverton, Berbice",
        "president": "jermainedaniels64@gmail.com",
        "secretary": "shalinahussain3014@gmail.com",
        "treasurer": "nicholas.jaundoo@yahoo.com",
        "clubsize": 12,
        "dues": 324
    },
    {
        "clubname": "Curaçao",
        "president": "ginocampbell@hotmail.com",
        "secretary": "csenior@mrc-carib.com",
        "treasurer": "roy.jansen@cw.gt.com",
        "clubsize": 85,
        "dues": 2295
    },
    {
        "clubname": "Demerara",
        "president": "lancelotkhan@gmail.com",
        "secretary": "cristelcheong592@gmail.com",
        "treasurer": "ram_eshs@yahoo.com",
        "clubsize": 59,
        "dues": 1593
    },
    {
        "clubname": "Diego Martin, Port of Spain",
        "president": "aj.learmondcriqui@gmail.com",
        "secretary": "sisuglobalwellness@gmail.com",
        "treasurer": "gpaul425@gmail.com",
        "clubsize": 18,
        "dues": 486
    },
    {
        "clubname": "Dominica",
        "president": "ndshillingfordinc@gmail.com",
        "secretary": "grayson.stedman.jr@hotmail.com",
        "treasurer": "d4ldavid@yahoo.com",
        "clubsize": 47,
        "dues": 1269
    },
    {
        "clubname": "Felicity/Charlieville",
        "president": "dianamaharaj.16rcfc@gmail.com",
        "secretary": "dianechinpire@yahoo.com",
        "treasurer": "kala.trebouhansingh@gmail.com",
        "clubsize": 18,
        "dues": 486
    },
    {
        "clubname": "Fort-de-France",
        "president": "cl@legadom.com",
        "secretary": "elzerk-tawfik@hotmail.fr",
        "treasurer": "bonnel.nc@gmail.com",
        "clubsize": 24,
        "dues": 648
    },
    {
        "clubname": "Fort-de-France Ouest",
        "president": "yves.cespedes@gmail.com",
        "secretary": "snobourg@gmail.com",
        "treasurer": "magplus1@orange.fr",
        "clubsize": 14,
        "dues": 133
    },
    {
        "clubname": "Fort-de-France Sud",
        "president": "lioty.sarotte@lcs-antilles.com",
        "secretary": "sonia.lameynardie@orange.fr",
        "treasurer": "isabelle.puren@orange.fr",
        "clubsize": 27,
        "dues": 729
    },
    {
        "clubname": "François",
        "president": "varlinvalerie25@gmail.com",
        "secretary": "caren.joseph1@gmail.com",
        "treasurer": "leonce.residant@gmail.com",
        "clubsize": 21,
        "dues": 567
    },
    {
        "clubname": "Garden City",
        "president": "alvinar@hotmail.com",
        "secretary": "m_desantos@demeraramutual.com",
        "treasurer": "onieca@gmail.com",
        "clubsize": 34,
        "dues": 918
    },
    {
        "clubname": "Georgetown",
        "president": "dkissoon@londonhousechambers.com",
        "secretary": "nvieira23@yahoo.com",
        "treasurer": "gparmesar17@yahoo.com",
        "clubsize": 78,
        "dues": 2106
    },
    {
        "clubname": "Georgetown Central",
        "president": "peterpompey2bacif@gmail.com",
        "secretary": "thalissa.mcclure@gmail.com",
        "treasurer": "knekah@yahoo.com",
        "clubsize": 44,
        "dues": 1188
    },
    {
        "clubname": "Grande Terre Pointe Des Chateaux",
        "president": "aline750@orange.fr",
        "secretary": "pascaletang7@gmail.com",
        "treasurer": "carlosaly92@gmail.com",
        "clubsize": 9,
        "dues": 243
    },
    {
        "clubname": "Grenada",
        "president": "collinfrancis@gmail.com",
        "secretary": "amcphail@sgu.edu",
        "treasurer": "karadianawarner@gmail.com",
        "clubsize": 32,
        "dues": 864
    },
    {
        "clubname": "Grenada East",
        "president": "kishamiller1@hotmail.com",
        "secretary": "willvorn@hotmail.com",
        "treasurer": "jenniferkathleenm77@yahoo.com",
        "clubsize": 19,
        "dues": 513
    },
    {
        "clubname": "Gros Islet",
        "president": "lenijo@gmail.com",
        "secretary": "rufina.daniel88@gmail.com",
        "treasurer": "Astridmondesir@gmail.com",
        "clubsize": 43,
        "dues": 1161
    },
    {
        "clubname": "Ile de Cayenne-Est",
        "president": "cedric357.berton@gmail.com",
        "secretary": "dondon.ghislaine@orange.fr",
        "treasurer": "virayie.joel@gmail.com",
        "clubsize": 11,
        "dues": 297
    },
    {
        "clubname": "Kourou",
        "president": "h1592-gm@accor.com",
        "secretary": "louise.arelgolitin@wanadoo.fr",
        "treasurer": "yves.blondeau@eiffage.com",
        "clubsize": 29,
        "dues": 783
    },
    {
        "clubname": "Lamentin",
        "president": "clement_fontanet@hotmail.fr",
        "secretary": "fabricemario@gmail.com",
        "treasurer": "combesdefontis@gmail.com",
        "clubsize": 35,
        "dues": 945
    },
    {
        "clubname": "Liamuiga (Saint Kitts)",
        "president": "nikkiel27@gmail.com",
        "secretary": "Meagre44@gmail.com",
        "treasurer": "tash8161@gmail.com",
        "clubsize": 25,
        "dues": 675
    },
    {
        "clubname": "Maracas/St. Joseph",
        "president": "kevin.morang@yahoo.com",
        "secretary": "hema.soon@gmail.com",
        "treasurer": "dolcefp24@gmail.com",
        "clubsize": 25,
        "dues": 675
    },
    {
        "clubname": "Maraval",
        "president": "kirwinsamuel@gmail.com",
        "secretary": "miss_pat@hotmail.com",
        "treasurer": "annmarienarine@gmail.com",
        "clubsize": 27,
        "dues": 729
    },
    {
        "clubname": "Marie-Galante",
        "president": "corinne.morandais@orange.fr",
        "secretary": "harry100461@gmail.com",
        "treasurer": "sandrine.bonbon@ac-guadeloupe.fr",
        "clubsize": 11,
        "dues": 297
    },
    {
        "clubname": "Montserrat",
        "president": "yves7057@gmail.com",
        "secretary": "audrisjb@gmail.com",
        "treasurer": "nduberry1@gmail.com",
        "clubsize": 22,
        "dues": 594
    },
    {
        "clubname": "New Amsterdam",
        "president": "rafeekkassim@yahoo.com",
        "secretary": "charlyn_artiga@yahoo.com",
        "treasurer": "controlpest11@yahoo.com",
        "clubsize": 23,
        "dues": 621
    },
    {
        "clubname": "Paramaribo",
        "president": "melstak@gmail.com",
        "secretary": "nancydel@yahoo.com",
        "treasurer": "marc_lie_kwie@hotmail.com",
        "clubsize": 66,
        "dues": 1782
    },
    {
        "clubname": "Paramaribo Central",
        "president": "mparsan@fatum-suriname.com",
        "secretary": "wramautarsing@hotmail.com",
        "treasurer": "vinaybaldew@gmail.com",
        "clubsize": 69,
        "dues": 1863
    },
    {
        "clubname": "Paramaribo Quota",
        "president": "diana.halfhide@gmail.com",
        "secretary": "msanches1@hotmail.com",
        "treasurer": "chantalreiziger@outlook.com",
        "clubsize": 29,
        "dues": 783
    },
    {
        "clubname": "Paramaribo Residence",
        "president": "etmdams@gmail.com",
        "secretary": "ymohabir75@gmail.com",
        "treasurer": "timothy.mendonca@yahoo.com",
        "clubsize": 62,
        "dues": 1674
    },
    {
        "clubname": "Penal",
        "president": "dramsingh78@gmail.com",
        "secretary": "sharmilla.bhimull@gmail.com",
        "treasurer": "karmalain@hotmail.com",
        "clubsize": 24,
        "dues": 648
    },
    {
        "clubname": "Piarco",
        "president": "bevoncook@gmail.com",
        "secretary": "peeyush3@hotmail.com",
        "treasurer": "melicia_a@yahoo.com",
        "clubsize": 14,
        "dues": 378
    },
    {
        "clubname": "Point Fortin",
        "president": "hisgloconcepts@gmail.com",
        "secretary": "arlene1906@live.co.uk",
        "treasurer": "s.okeiffe@gmail.com",
        "clubsize": 16,
        "dues": 432
    },
    {
        "clubname": "Pointe-A-Pierre",
        "president": "deane_ronald@yahoo.com",
        "secretary": "jochebedbajnath01@gmail.com",
        "treasurer": "davidsonlalloo@gmail.com",
        "clubsize": 24,
        "dues": 648
    },
    {
        "clubname": "Pointe-à-Pitre",
        "president": "jean-luc.edom@orange.fr",
        "secretary": "camiens@gmail.com",
        "treasurer": "rene.rotarydpap@gmail.com",
        "clubsize": 60,
        "dues": 1620
    },
    {
        "clubname": "Pointe-à-Pitre Est",
        "president": "frank.maynadie@wanadoo.fr",
        "secretary": "tasha971@hotmail.com",
        "treasurer": "gilthemine@hotmail.fr",
        "clubsize": 10,
        "dues": 270
    },
    {
        "clubname": "Pointe-à-Pitre Fleur d'Epée",
        "president": "ch.cuartero@cuartero-avocats.com",
        "secretary": "olivier.loisy@yahoo.fr",
        "treasurer": "didominique@orange.fr",
        "clubsize": 32,
        "dues": 864
    },
    {
        "clubname": "Pointe-à-Pitre-Jarry",
        "president": "vbka.marie@gmail.com",
        "secretary": "gillespignalosa@gmail.com",
        "treasurer": "guyhalley@wanadoo.fr",
        "clubsize": 12,
        "dues": 324
    },
    {
        "clubname": "Pont de l'Alliance ",
        "president": "kalu97115@gmail.com",
        "secretary": "odilelawmun@hotmail.fr",
        "treasurer": "rcpda.emmanuel@gmail.com",
        "clubsize": 11,
        "dues": 297
    },
    {
        "clubname": "Port of Spain",
        "president": "jdegannes@hotmail.com",
        "secretary": "rjkirton@kirtontt.com",
        "treasurer": "davidramsaran@yahoo.com",
        "clubsize": 31,
        "dues": 837
    },
    {
        "clubname": "Port of Spain West",
        "president": "wherbert100@hotmail.com",
        "secretary": "jacquelinelookloy@gmail.com",
        "treasurer": "garthsobers@gmail.com",
        "clubsize": 48,
        "dues": 876
    },
    {
        "clubname": "Portsmouth",
        "president": "McMarara@gmail.com",
        "secretary": "mrzrollee@gmail.com",
        "treasurer": "mariekevanasten@mail.com",
        "clubsize": 22,
        "dues": 594
    },
    {
        "clubname": "Princes Town",
        "president": "andreabhagwandeen@gmail.com",
        "secretary": "wa26joseph@gmail.com",
        "treasurer": "cooperochiengh@gmail.com",
        "clubsize": 21,
        "dues": 567
    },
    {
        "clubname": "Remire Montjoly",
        "president": "tigroov@gmail.com",
        "secretary": "cheddyothily@gmail.com",
        "treasurer": "technic.techno@wanadoo.fr",
        "clubsize": 38,
        "dues": 1026
    },
    {
        "clubname": "Saint Pierre",
        "president": "chabinette01@wanadoo.fr",
        "secretary": "stephanierossignol14@gmail.com",
        "treasurer": "jadenajjar@hotmail.com",
        "clubsize": 27,
        "dues": 729
    },
    {
        "clubname": "Saint-Laurent du Maroni-Mana (Ouest Guyanais)",
        "president": "mathieu.monamicq@gmail.com",
        "secretary": "jberthelot@apgguyane.fr",
        "treasurer": "ericleva@live.fr",
        "clubsize": 14,
        "dues": 378
    },
    {
        "clubname": "San Fernando",
        "president": "rbose@caribel.com",
        "secretary": "MohammedSalima80@gmail.com",
        "treasurer": "jzsamlalsingh@yahoo.co.uk",
        "clubsize": 17,
        "dues": 459
    },
    {
        "clubname": "San Fernando-South",
        "president": "decourseyr@gmail.com",
        "secretary": "ravita.babwah@gmail.com",
        "treasurer": "",
        "clubsize": 22,
        "dues": 594
    },
    {
        "clubname": "San Juan",
        "president": "nadran@hotmail.com",
        "secretary": "ddipchansingh@yahoo.com",
        "treasurer": "khowai@live.com",
        "clubsize": 15,
        "dues": 405
    },
    {
        "clubname": "Sangre Grande",
        "president": "marlon.ramanan@pcpharmtt.com",
        "secretary": "arleen.sinanan@outlook.com",
        "treasurer": "ssooklal79@gmail.com",
        "clubsize": 14,
        "dues": 378
    },
    {
        "clubname": "Schoelcher",
        "president": "clovis.fd@gmail.com",
        "secretary": "mcadasse@gmail.com",
        "treasurer": "valerie.therosiet@wanadoo.fr",
        "clubsize": 42,
        "dues": 1134
    },
    {
        "clubname": "Southwest-Tobago",
        "president": "chateaublanc8026@gmail.com",
        "secretary": "aneshacaruth@gmail.com",
        "treasurer": "sharmarmel56@gmail.com",
        "clubsize": 20,
        "dues": 540
    },
    {
        "clubname": "St. Augustine",
        "president": "vishnu.balroopstt@gmail.com",
        "secretary": "melissa.senhouse@patandmax.com",
        "treasurer": "debbie.battersby@gmail.com",
        "clubsize": 49,
        "dues": 1323
    },
    {
        "clubname": "St. Augustine West",
        "president": "vlopezphoto@gmail.com",
        "secretary": "hplbruce1963@gmail.com",
        "treasurer": "raycrike@yahoo.com",
        "clubsize": 26,
        "dues": 702
    },
    {
        "clubname": "St. Kitts",
        "president": "charlomorts@hotmail.com",
        "secretary": "kara.daly@outlook.com",
        "treasurer": "scottcaines@cainesinc.com",
        "clubsize": 34,
        "dues": 864
    },
    {
        "clubname": "St. Lucia",
        "president": "evertonlincolnsealy@gmail.com",
        "secretary": "natoya.rotary@gmail.com",
        "treasurer": "keturah.donai@nsdcslu.com",
        "clubsize": 60,
        "dues": 1620
    },
    {
        "clubname": "St. Vincent",
        "president": "mikasha_r@hotmail.com",
        "secretary": "felicia_waldron@hotmail.com",
        "treasurer": "dc3464@gmail.com",
        "clubsize": 30,
        "dues": 810
    },
    {
        "clubname": "St. Vincent South",
        "president": "glynis.r.hay@gmail.com",
        "secretary": "haydock@vincysurf.com",
        "treasurer": "wyllie_crystal@hotmail.com",
        "clubsize": 20,
        "dues": 540
    },
    {
        "clubname": "Stabroek-Georgetown",
        "president": "nyetb12@gmail.com",
        "secretary": "gmmcgy@yahoo.com",
        "treasurer": "patpp49@yahoo.com",
        "clubsize": 18,
        "dues": 486
    },
    {
        "clubname": "Tobago",
        "president": "philbertwilliams@gmail.com",
        "secretary": "reneejwheeler@gmail.com",
        "treasurer": "moyzwilliams@gmail.com",
        "clubsize": 16,
        "dues": 432
    },
    {
        "clubname": "Willemstad",
        "president": "bonicemaaike@gmail.com",
        "secretary": "jelkaat@hotmail.com",
        "treasurer": "bennovanleeuwen@gmail.com",
        "clubsize": 35,
        "dues": 945
    },
    {
        "clubname": "",
        "president": "",
        "secretary": "",
        "treasurer": "",
        "clubsize": 2353,
        "dues": 62812
    }
]

    console.log(this.seed[0].clubname)


    for(var x=0; this.seed.length; x++){

        let treasurer = (this.seed[x].treasurer) ? this.seed[x].treasurer : ''
        let president = (this.seed[x].president) ? this.seed[x].president : ''
        let secretary = (this.seed[x].secretary) ? this.seed[x].secretary : ''

        //console.error(x, treasurer)

        
        fb.clubsCollection.add({
            clubname: this.seed[x].clubname,
            clubsize: Number(this.seed[x].clubsize),
            dues: Number(this.seed[x].dues),
			arrears: 0,
            paid: 0,
            emails:{president, secretary, treasurer}

        })
        .then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        })
        
    }  */

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.summary-table td {
	text-align: left;
	border-bottom: 1px solid #f1f1f1;
	padding: 10px 0;
}

.summary-table td:nth-child(2) {
	text-align: right;
}

.payment-form {
	width: 50%;
	margin: auto;
	text-align: left;
	padding: 0;
	padding-bottom: 60px;
}

.form-control {
	display: block;
	width: 97%;
	margin: 5px 0;
	padding: 15px 10px;
	font-size: 1.01em;
	border: 1px solid #f1f1f1;
}

.form-radio {
	width: 97%;
	margin: 5px 0;
	padding: 10px;
	font-size: 1.01em;
	border: 1px solid #f1f1f1;
}

.btn {
	width: 100%;
	padding: 15px 0;
	border: 0;
	margin: 10px 0;
	font-size: 1.01em;
	border-radius: 0;
}

.btn-primary {
	background: #005daa;
	color: #fff;
}

.back-button {
	color: #005daa;
	padding: 15px;
	border: 1px solid #005daa;
	margin-top: 15px;
	background: #fff;
}

.error {
	color: #f00;
}

.logo {
	max-width: 200px;
	height: auto;
}

.alert-primary {
	color: #004085;
	background-color: #cce5ff;
	border-color: #b8daff;
}

.alert {
	position: relative;
	padding: .75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: .25rem;
}

.h3,
h3 {
	font-size: 1.75rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: .5rem;
	font-weight: 500;
	line-height: 1.2;
	margin-top: 0;
	padding-top: 0;
}

.h5,
h5 {
	font-size: 1.25rem;
}

select .form-control {
	width: 100%;
}

table {
	width: 100%;
	margin: 10px 0;
}

table .no-border {
	border: 0;
}

h3 {
	margin: 40px 0 0;
}

ul {
	list-style-type: none;
	padding: 0;
}

li {
	display: inline-block;
	margin: 0 10px;
}

a {
	color: #42b983;
}

small {
	font-size: 14px;
}

fieldset {
	border: 0;
	padding: 0;
}

:disabled {
	background: #999;
}

@media only screen and (max-width: 780px) {
	.payment-form {
		width: 80%;
	}
}
</style>
