<template>
	<div align="left" style="padding: 2rem">
		<h1>Payments Summary</h1>
		<table class="paymentsTable" cellspacing="0">
			<thead>
				<th align="left">Date</th>
				<th align="left">Club</th>
				<th align="left">Payor</th>
				<th align="right">Amount</th>
			</thead>
			<tbody>
				<tr v-for="(pyt, index) in payments" :key="index">
					<td class="col-12 col-lg-2">
						{{ String(new Date(Number(`${pyt.date}000`))).slice(0, 15) }}
					</td>
					<td class="col-12 col-lg-3">
						{{ pyt.order_id }}
					</td>
					<td class="col-12 col-lg-3">
						{{ pyt.rotary_club }}
					</td>
					<td align="right" class="col-12 col-lg-3">
						{{ pyt.amount | currency }}
					</td>
				</tr>
			</tbody>
		</table>

		<h1>Club Summary</h1>
		<table class="paymentsTable" cellspacing="0">
			<thead>
				<th align="left">Date</th>
				<th align="left">Club</th>
				<th align="left">Payor</th>
				<th align="right">Amount</th>
			</thead>
			<tbody>
				<tr v-for="(club, index) in clubs" :key="index">
					<td class="col-12 col-lg-2">

					</td>
					<td class="col-12 col-lg-3">
						{{ club.clubname.toUpperCase() }}
					</td>
					<td class="col-12 col-lg-3">

					</td>
					<td align="right" class="col-12 col-lg-3">
						{{ club.paid | currency }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
const fb = require('../firebaseConfig.js')
export default {
	name: 'Payments',
	props: {

	},
	filters: {
		currency(value) {
			if (!value) return '0.00'
			value = Number(value)
			return value.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
		}
	},
	computed: {

	},
	data() {
		return {
			payments: [],
			clubs: [],
			sum: 0,
		}
	},
	methods: {

	},
	mounted() {

		fb.duesPaymentsCollection.onSnapshot((querySnapshot) => {

			querySnapshot.forEach((doc) => {
				this.payments.push({ id: doc.id, ...doc.data() })
			})
		})

		fb.clubsCollection.onSnapshot((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				this.clubs.push({ id: doc.id, ...doc.data() })
			})
		})

		fb.rtClubsCollection.onSnapshot((querySnapshot) => {
			querySnapshot.forEach((doc) => {
				this.clubs.push({ id: doc.id, ...doc.data() })
			})
		})

	}
}
</script>
<style>
.paymentsTable td,
.paymentsTable th {
	border: 1px solid #f1f1f1;
	padding: .7rem;
}
</style>
