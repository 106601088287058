import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Payments from '../views/Payments.vue'
import DuesPayments from '../views/DuesPayments.vue'
import RotaryDues from '../components/RotaryDues.vue'
import RotaractDues from '../components/RotaractDues.vue'
import Ryla from '../components/Ryla.vue'

Vue.use(VueRouter)
  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/ryla',
    name: 'Ryla',
    component: Ryla
  },
  {
    path: '/rotary-dues',
    name: 'RotaryDues',
    component: RotaryDues
  },
  {
    path: '/rotaract-dues',
    name: 'RotaractDues',
    component: RotaractDues
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments
  },
  {
    path: '/dues-payments',
    name: 'DuesPayments',
    component: DuesPayments
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
