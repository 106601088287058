<template>
  <div>
    
    <form class="payment-form" v-show="activetab=='form'">
      <p align="right"><a href="/" class="text-primary" style="color:#005daa;">[GO BACK]</a></p>
      
      <h2 align="center"><strong>Rotary District 7030 RYLA Contribution Payment Form</strong><br/>&nbsp;<br/>&nbsp;</h2>
		<label>Rotary club</label>
        <select class="form-control" v-model="clubname" @change="clubnameChange">
            <option value="0" selected="true" disabled>Choose one</option>
            <!-- <option disabled>--- Rotary Clubs ---</option> -->
            <option v-for="(club, index) in clubs" :key="index" :value="{id:club.id, clubname:club.data.clubname, size:club.data.clubsize, amt:club.data.dues, paid:club.data.paid, emails:club.data.emails}">RC {{club.data.clubname}}</option>
            <option value="-1">My Rotary Club is NOT Listed</option>
            <!-- <option disabled>--- Rotaract Clubs ---</option>
            <option v-for="(club, rtcindex) in rtcclubs" :key="`rtc${rtcindex}`" :value="{id:club.id, clubname:club.data.clubname, emails:{}}">{{club.data.clubname}}</option>
            <option value="-1">My Rotaract Club is NOT Listed</option> -->
        </select>
        <div v-show="clubname==='-1'">
            <input type="text" class="form-control" v-model="unlistedName" placeholder="Enter Club Name" />
            <input type="number" class="form-control" v-model="unlistedId" placeholder="Enter Club ID" />
            <input type="number" class="form-control" v-model="unlistedDistrict" placeholder="Enter District ID" />
        </div>
        <p style="margin-bottom:40px;">&nbsp;</p>
        <div v-show="clubname!=0 && !showstripe" >
            <div>
                <h2>Number of Participants</h2>
                <select class="form-control" v-model="rylaAmount">
                    <option value="150">Up to 20 Participants (USD $150)</option>
                    <option value="200">21 to 30 Participants (USD $200)</option>
                    <!-- <option value="450">41 to 60 Participants (USD $450)</option>
                    <option value="600">61 to 80 Participants (USD $600)</option>
                    <option value="750">81 to 100 Participants (USD $750)</option> -->
                </select>
            </div>
            <!--table class="summary-table">
                <tr>
                  <td>Number of members in your club (as at 1st July 2020)</td>
                  <td align="right">{{clubname.size}}</td>
                </tr>
                <tr>
                  <td>Per Capita Charges (USD) <br><small>District dues - $25.00<br>Disaster relief fund - $2.00</small></td>
                  <td align="right">$27.00</td>
                </tr>
                <tr>
                  <td><strong>Amount due</strong></td>
                  <td align="right"><strong>${{maxpayment | currency}}</strong></td>
                </tr>
            </table-->
            <!--div>
                <div class="form-radio">
                    <input type="radio" id="payfull" value="1" v-model="picked">
                    <label for="payfull">Pay full amount</label>
                </div>
                <div class="form-radio">
                    <input type="radio" id="paypart" value="2" v-model="picked">
                    <label for="paypart">Pay other amount</label>
                    <input type="number" min="100" step="0.1" class="form-control" v-show="picked==2" v-model="amountdue" :max="maxpayment" placeholder="Enter payment amount" />
                    <small class="error" v-show="picked==2">{{payamounterror}}</small>
                </div>
            </div-->
            
            <div style="margin-top:40px;">
                <div>
                    <h2>Billing Information <small>(as it appears on credit card)</small></h2>
                </div>
                <div>
					<label>Full name</label>
                    <input type="text" class="form-control" v-model="fullname" placeholder="Enter your full name" />
					<br/>&nbsp;
                </div>
                <div>
					<label>Email address</label>
                    <input type="email" class="form-control" v-model="emailaddress" placeholder="Enter your email address" />
					<br/>&nbsp;
                </div>
                <div>
					<label>Phone number</label>
                    <input type="number" step="1" class="form-control" v-model="phone" placeholder="Enter your phone number" />
					<br/>&nbsp;
                </div>
                <div>
                    <button type="button" class="btn btn-primary" :disabled="!isformvalid" @click="btncontinueClick">Continue</button>
                </div>
                
            </div>



        </div>

        <!-- <p align="center" v-show="clubname=='0'"><img alt="Rotary District 7030 Logo logo" class="flyer" src="../assets/ryla_small_en.jpg"></p> -->

        <div v-show="showstripe && clubname!=0">
            <div>
                <h2>Payment Details</h2>
            </div>
            <div class="alert alert-primary" role="alert">
				<br/>&nbsp;
                <h4><strong>Notice to Scotiabank and Republic Bank Credit Card Customers</strong></h4>
                <h4 class="mb-5">Please contact the bank's credit card center before attempting to make a payment using your credit card.</h4><br/>&nbsp;
            </div>
            <p align="right"></p>
            <table class="summary-table">
                <tr>
                  <td>Payment amount</td>
                  <td align="right">${{rylaAmount | currency}}</td>
                </tr>
                <tr>
                  <td>Processing fees</td>
                  <td align="right">${{fees | currency}}</td>
                </tr>
                <tr>
                  <td><strong>Total</strong><br><small>Your payment details will be processed by Soca Islands LLC via Stripe.com.</small></td>
                  <td align="right"><strong>${{(stripeamount/100) | currency}}</strong></td>
                </tr>
            </table>
            <div class="form-radio">
                <input type="checkbox" id="terms" value="true" v-model="agreeterms">
                <label for="terms">I have read and agree to the terms and conditions.</label>
            </div>
            <div>
                <input type="hidden" value="" name="stripeamount" id="stripeamount" v-model="stripeamount">
                <fieldset class="form-group">
                  <div class="form-row col-12 form-control">  
                      <label for="card-element" style="width:100%;">
                        
                      </label>
                      <!--input type="text" v-model="fullname" class="form-control" style="margin-bottom:5px;" placeholder="Cardholder's full name"/-->

                      <div id="card-element" class=""></div>
                  
                      <!-- Used to display form errors. -->
                      <div id="card-errors" role="alert" style="color:#ff0000;"> </div>
                  </div>
                </fieldset> 
                <button type="button" :data-secret="clientsecret" id="card-button" class="btn btn-primary btn-block btn-lg mb-2"  :disabled="!ispaymentformvalid || isbusy">
                      <span v-show="!isbusy">Pay Now</span>
                      <span v-show="isbusy"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Please wait ...</span>
                </button>
                <button type="button" class="btn back-button" @click="btnbackClick">Go back...</button>
            </div>
        </div>
    </form>
    <div align="center" v-show="activetab=='thankyou'">
        <h2>Thank you for your payment!</h2>
        <a href="/" class="btn btn-primary" style="padding:10px 20px; text-decoration:none;">Continue</a>
    </div>
    
  </div>
</template>
<script type="text/javascript" src="//js.stripe.com/v3/"></script>
<script>
const fb = require('../firebaseConfig.js')
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  filters: {
      currency (value) {
          if (!value) return ''
          value = Number(value)
          return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  computed:{
      isformvalid(){
          //return true
          if(this.clubname=='0') return false
          if(this.clubname=='-1') {
              if(!this.unlistedName) return false
              if(!this.unlistedId) return false
              if(!this.unlistedDistrict) return false
          }
          if(!this.fullname) return false
          if(!this.emailaddress) return false
          if(!this.phone) return false
          return true
      },
      ispaymentformvalid(){
          if(!this.agreeterms) return false
          return true
      },
      payamounterror(){

          if(this.picked==2 && this.amountdue < 100){

            return 'Error: Invalid payment amount'
          }

          if(this.picked==2 && this.amountdue > this.maxpayment){
            return 'Error: You cannot pay more than you currently owe.'
          }

          return ''
      },
      /*fees(){
          return (((this.amountdue + 0.35)/0.945)-this.amountdue)
      }*/

  },
  data (){ 
      return {
          dues:27,
          rylaAmount:150,
          picked:'',
          clubname:'0',
          payamount:'',
          amountdue: 540,
          maxpayment: 540,
          fullname:'',
          emailaddress:'',
          phone:'',
          stripeamount:0,
          fees:0,
          isbusy:false,
          clientsecret:'',
          showstripe:false,
          agreeterms:false,
          clientsecret:'',
          pytintent:'',
          API_URL:'https://dues.rotarydistrict7030.org/api',
          clubs:[],
          rtcclubs:[],
          activetab:'form',
          ordernum:'',
          seed:[],
          unlistedName:'',
          unlistedId:'',
          unlistedDistrict:'',
      } 
  },
  methods:{
    clubnameChange(){
        this.showstripe = false
        this.maxpayment = (Number(this.clubname.size)*this.dues)-Number(this.clubname.paid)
        this.amountdue = (Number(this.clubname.size)*this.dues)-Number(this.clubname.paid)
    },
    btnbackClick(){
        this.showstripe = false
    },
    btncontinueClick(){
        this.amountdue = Number(this.rylaAmount)
        this.stripeamount = Math.trunc(((this.amountdue + 0.30)/0.94)*100)
        this.fees = ((this.amountdue + 0.30)/0.94)-this.amountdue
        this.showstripe = true
        let $this = this
        const stripe = Stripe('pk_live_NO5i9A0rJetFGqZ99mLPeSpP') //pk_test_itCmziCcsavym8T71gROTOhj  pk_live_NO5i9A0rJetFGqZ99mLPeSpP

        var elements = stripe.elements()

        let style = {
            base: {
                color: '#32325d',
                fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '1.15em',
                '::placeholder': {
                      color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }

        // Create an instance of the card Element.
        let card = elements.create('card', {style: style})

        // Add an instance of the card Element into the `card-element` <div>.
        card.mount('#card-element');

        // Handle real-time validation errors from the card Element.
        let displayError = document.getElementById('card-errors')
        card.addEventListener('change', function(event) {
            $this.isbusy = false
            if (event.error) {
                displayError.textContent = event.error.message
            } else {
                displayError.textContent = ''
            }
        })

        let submitButton = document.getElementById('card-button')

        submitButton.addEventListener('click', async () =>{

            //console.log(ev)

            this.isbusy = true

            if(this.fullname==''  || this.fullname.length<4){
                this.isbusy = false
                displayError.textContent = "Cardholder's name is missing"
                return false
            }

            let orderDesc = "Payment of Rotary District 7030 Dues"

            if(this.clubname=='-1'){    
                console.log("in the mix")  
                this.clubname={}
                this.clubname.clubname = this.unlistedName
                this.clubname.clubid = this.unlistedId
                this.clubname.district = this.unlistedDistrict
                this.clubname.emails = {}
            }

            console.log("checking", this.clubname)

            if(this.clientsecret==''){
                this.ordernum = Math.floor(Date.now())


                //get stripe payment intent
                //console.log("stripe amount: ", this.stripeamount)
                if(this.stripeamount <1) return false

                let striperesponse = await fetch(this.API_URL+'/stripe/create/'+this.ordernum+'!'+this.stripeamount+'!'+encodeURI(this.fullname)+'!'+encodeURI(this.clubname.clubname)+'!'+encodeURI(this.emailaddress)).then((response)=> {
                  return response.json()
                })

                this.clientsecret = striperesponse.client_secret
                this.pytintent = striperesponse.payment_intent
                //console.log(this.clientsecret, " / ", this.pytintent)
            }


            stripe.confirmCardPayment(this.clientsecret,
                {
                  payment_method: {
                  card: card,
                  billing_details: {
                    name: this.fullname,
                    phone: this.phone,
                    email: this.emailaddress,
                  },
                },
            }).then(async (result) =>{
                if (result.error) {
                    document.getElementById('card-errors').textContent = result.error.message + " Please contact your bank for further information." 
                    this.isbusy = false
                } else{

                    fb.paymentsCollection.add({
                        amount: Number(this.rylaAmount),
                        desc: 'RYLA Payment',
                        type: 'ryla',
                        date: this.ordernum,
                        club: this.clubname,
                        payor: {name:this.fullname, email:this.emailaddress, phone:this.phone},
                    }).then(doc=>{
                        console.log(doc)
                    })

                    let emailaddresses = 'treasurer@rotarydistrict7030.org, interactryla2022@gmail.com, ritamerkies@yahoo.com, franco.diaz@bearingpointcaribbean.com, sean.paddy@gmail.com, ' + this.emailaddress

                    if(this.clubname.emails.president) emailaddresses +=  ", " + this.clubname.emails.president

                    if(this.clubname.emails.secretary) emailaddresses +=  ", " + this.clubname.emails.secretary

                    if(this.clubname.emails.treasurer) emailaddresses += ", " + this.clubname.emails.treasurer

                    //console.log('email', emailaddresses)

                    let data = {
                        club:{clubemails:emailaddresses, clubname:this.clubname.clubname, dues:this.rylaAmount},
                        payment:{orderid: this.ordernum, paid:this.rylaAmount, payor: this.fullname + ' - ' +  this.emailaddress + ' - ' + this.phone },
                        subject:true
                    }

                    await fetch(this.API_URL+'/sendemail',{
                        method: 'POST', 
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'same-origin', 
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify(data)
                    }).then((response)=> {
                        
                        return response.json()

                    })

                    this.clientsecret = ''
                    this.isbusy = false
                    
                    this.activetab = "thankyou"


                }
            })

        })
    }
  },
  mounted(){
      //this.ordernum = Math.floor(Date.now() / 1000)
      fb.clubsCollection.orderBy("clubname", "asc").onSnapshot((querySnapshot)=>{
          let i=0
          this.clubs.length=0
          querySnapshot.forEach((doc)=>{
              this.clubs.push({id:doc.id, data:doc.data()})
              i++
          })
      })

      fb.rtcClubsCollection.orderBy("clubname", "asc").onSnapshot((querySnapshot)=>{
          let i=0
          this.rtcclubs.length=0
          querySnapshot.forEach((doc)=>{
              this.rtcclubs.push({id:doc.id, data:doc.data()})
              i++
          })
      })

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

.summary-table td{
    text-align:left; 
    border-bottom:1px solid #f1f1f1; 
    padding:10px 0;
}

.summary-table td:nth-child(2){
    text-align:right;
}

.payment-form{
    width:50%;
    margin:auto;
    text-align:left;
    padding:0;
    padding-bottom:60px;
}

.form-control{
    display:block;
    width:97%;
    margin: 5px 0;
    padding: 15px 10px;
    font-size:1.01em;
    border:1px solid #f1f1f1;
}

.form-radio{
    width:97%;
    margin: 5px 0;
    padding:10px;
    font-size:1.01em;
    border:1px solid #f1f1f1;
}

.btn{
    width:100%;
    padding:15px 0;
    border:0;
    margin:10px 0;
    font-size:1.01em;
    border-radius:0;
}

.btn-primary{
    background:#005daa;
    color:#fff;
}

.back-button{
    color:#005daa;
    padding:15px;
    border:1px solid #005daa;
    margin-top:15px;
    background:#fff;
}

.error{
    color:#f00;
}

.logo{
  max-width:200px;
  height:auto;
}

.flyer{
  width:100%;
  max-width:500px;
  height:auto;
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}
.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.h3, h3 {
    font-size: 1.75rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
    margin-top:0;
    padding-top:0;
}

.h5, h5 {
    font-size: 1.25rem;
}

select .form-control{
  width:100%;
}

table{
    width:100%;
    margin:10px 0;
}

table .no-border{
    border:0;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

small{
    font-size:14px;
}

fieldset{
    border:0;
    padding:0;
}

:disabled{
    background:#999;
}

@media only screen and (max-width: 780px) {
    .payment-form{
        width:80%;
    }
}
</style>
