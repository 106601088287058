import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// firebase init goes here
const config = {
    apiKey: "AIzaSyCoSK7QSOs6IMat7S3KbZLvco_k0B8_8IQ",
    authDomain: "dc7030-dues.firebaseapp.com",
    databaseURL: "https://dc7030-dues.firebaseio.com",
    projectId: "dc7030-dues",
};

firebase.initializeApp(config);

// firebase utils
const db = firebase.firestore();
//const storageRef = firebase.storage().ref()
const auth = firebase.auth();
const currentUser = auth.currentUser;

// date issue fix according to firebase
//const settings = {
//    timestampsInSnapshots: true
//}
//db.settings(settings)

// firebase collections
const clubsCollection = db.collection("rotaryclubs2024");
const rtcClubsCollection = db.collection("rortactclubs");
const rtClubsCollection = db.collection("rotaractclubs2023");
const paymentsCollection = db.collection("clubpayments");
const duesPaymentsCollection = db.collection("payments");

export {
    firebase,
    db,
    auth,
    currentUser,
    clubsCollection,
    rtClubsCollection,
    rtcClubsCollection,
    paymentsCollection,
    duesPaymentsCollection,
};
