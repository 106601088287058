<template>
    <div>
        <!--img alt="Rotary District 7030 Logo logo" class="logo" src="../assets/rotary7030_logo.png"-->
        <!--RotaractDues /-->
        <h2 align="center">Rotary District 7030 Payment Portal</h2>
        <hr size="0" color="#dddddd" />
        <p align="center"><br />&nbsp;</p>
        <p align="center">
            <a href="/rotary-dues" target="_rotarydues"
                >To pay your <strong>Rotary Club</strong> dues, please click here.</a
            >
        </p>
        <p align="center">
            <a href="/rotaract-dues" target="_rotaractdues"
                >To pay your <strong>Rotaract Club</strong> dues, please click here.</a
            >
        </p>
        <p align="center">
            <a href="https://events.rotarydistrict7030.org/rotary7030/ryla-7030-2023" target="_ryla"
                >To pay for <strong>RYLA Participants</strong>, please click here.</a
            >
        </p>
        <p align="center"><br />&nbsp;</p>
        <p align="center"><br />&nbsp;</p>
        <p align="center">
            <a href="/rotary-dues" target="_rotarydues">
                <img alt="Rotaract District 7030  logo" class="logo" src="../assets/rotary7030_logo.png" />
            </a>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <a href="/rotaract-dues" target="_rotaractdues">
                <img alt="Rotary District 7030  logo" class="logo" src="../assets/rotaract-7030-logo.png" />
            </a>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <a href="/ryla" target="_ryla">
                <img alt="RYLA logo" class="logo" src="../assets/ryla_logo.jpg" />
            </a>
        </p>
    </div>
</template>

<script>
// @ is an alias to /src
//import RotaractDues from '@/components/RotaractDues.vue'

export default {
    name: "Home",
    components: {
        // RotaractDues
    },
};
</script>

<style>
.logo {
    max-width: 200px;
    height: auto;
}
a {
    color: #0050a2 !important;
}
</style>
