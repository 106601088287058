<template>
  <div align="left" style="padding: 2rem">
    <h1>Payments Summary</h1>
    <h5>Count: {{payments.length}} / Sum: {{sum | currency}}</h5>
    <table class="paymentsTable" cellspacing="0">
    <thead>
      <th align="left">Date</th>
      <th align="left">Club</th>
      <th align="left">Payor</th>
      <th align="right">Amount</th>
    </thead>
    <tbody>
    <tr v-for="(pyt, index) in payments" :key="index">
        <td class="col-12 col-lg-2">
           {{String(new Date(Number(`${pyt.data.date}`))).slice(0,15)}}
        </td>
        <td class="col-12 col-lg-3">
          {{pyt.data.club.clubname.toUpperCase()}}
        </td>
        <td class="col-12 col-lg-3">
          {{pyt.data.payor.name.toUpperCase()}} - <small>{{pyt.data.payor.email}} / {{pyt.data.payor.phone}}</small>
        </td>
        <td  align="right" class="col-12 col-lg-3">
          {{pyt.data.amount | currency}}
        </td>
    </tr>
    </tbody>
    </table>
  </div>
</template>
<script>
const fb = require('../firebaseConfig.js')
export default {
  name: 'Payments',
  props: {

  },
  filters: {
      currency (value) {
          if (!value) return '0.00'
          value = Number(value)
          return value.toLocaleString("en-US", {minimumFractionDigits:2, maximumFractionDigits:2})
      }
  },
  computed:{

  },
  data (){ 
      return {
          payments:[],
          sum:0,
      } 
  },
  methods:{

  },
  mounted(){

      fb.paymentsCollection.where("date", ">", 1637239434523).orderBy("date", "desc").onSnapshot((querySnapshot)=>{
          this.payments.length=0
          this.sum = 0
          querySnapshot.forEach((doc)=>{
              if(!doc.data().refunded) {
                this.payments.push({id:doc.id, data:doc.data()})
                this.sum += doc.data().amount
              }
          })
      })

  }
}
</script>
<style>
  .paymentsTable td, .paymentsTable th{
      border:1px solid #f1f1f1;
      padding: .7rem;
  }
</style>
